import { configureStore, getDefaultMiddleware, ThunkAction, Action } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import { AnyAction, combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import authReducer from '../containers/LoginPage/reducers'
import userListReducer from '../containers/Administrator/UserListPage/reducers'
import dashboardReducer from '../containers/DashboardPage/reducers'
import merchantDetailsReducer from '../containers/MerchantPage/reducers'
import merchantSummaryReducer from '../containers/Transactions/MerchantSummaryPage/reducers'
import depositReducer from '../containers/Transactions/DepositPage/reducers'
import withdrawalReducer from '../containers/Transactions/WithdrawalPage/reducers'
import settlementReducer from '../containers/Transactions/SettlementPage/reducers'
import topUpReducer from '../containers/Transactions/TopUpPage/reducers'
import dailyBalanceReducer from '../containers/Report/DailyBalancePage/reducers'
import changePasswordReducer from '../containers/ChangePasswordPage/reducers'
import profileReducer from '../containers/ProfilePage/reducers'
import authLayoutReducer from '../containers/AuthLayout/reducers'
import { roleEditReducer, roleManagementReducer } from '../containers/Administrator/RoleManagementPage/reducers'
import transactionCallbackReducer from '../containers/Transactions/Components/ExpandedData/reducers'

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'authReducer', 
    'userListReducer', 
    'createUserReducer', 
    'dashboardReducer', 
    'merchantDetailsReducer',
    'merchantSummaryReducer',
    'depositReducer',
    'withdrawalReducer',
    'settlementReducer',
    'topUpReducer',
    'dailyBalanceReducer',
    'changePasswordReducer',
    'profileReducer'
  ]
}

const authConfig = {
  key: 'authReducer',
  storage: storage,
  whitelist: [
    'authToken', 
    'refreshToken',
    'role', 
    'selectList', 
    'isEnableLoginTwoFactor', 
    'isTwoFaLoginVerified', 
    'merchantId',
    'rolePermission',
    'googleLoginAuthUri'
  ]
}

const appReducers = combineReducers({
  authReducer: persistReducer(authConfig, authReducer),
  userListReducer,
  dashboardReducer,
  merchantDetailsReducer,
  merchantSummaryReducer,
  depositReducer,
  transactionCallbackReducer,
  withdrawalReducer,
  settlementReducer,
  topUpReducer,
  dailyBalanceReducer,
  changePasswordReducer,
  profileReducer,
  authLayoutReducer,
  roleEditReducer,
  roleManagementReducer,

})

const rootReducers = (state: RootReducer | undefined, action: AnyAction): RootReducer => {
  if (action.type === 'LOGOUT') {
    return appReducers(undefined, action)
  }

  return appReducers(state, action)
}

const persistedReducer = persistReducer<RootReducer>(persistConfig, rootReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH, 
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE, 
        REGISTER,
        'transactions/exportMerchantSummaryExcel/fulfilled',
        'transactions/exportDepositExcel/fulfilled',
        'transactions/exportWithdrawalExcel/fulfilled',
        'reports/exportDailyBalanceExcel/fulfilled'
      ]
    }
  })
})

const persistor = persistStore(store)

export { store, persistor }

export type RootReducer = ReturnType<typeof appReducers>
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>
