import styled from 'styled-components'
import { Button, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

const { Title } = Typography

// Login
const LoginContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: auto;
`
const FormContainer = styled.div`
    background: ${props => props.theme.colors.secondaryColor};
    border-radius: 4px;
    box-shadow: 0 0 100px rgb(0 0 0 / 8%);
    margin: 120px 0 0;
    max-width: 460px;
    padding: 42px;
    width: 100%;

    @media (max-width: 992px) and (orientation: landscape) {
        margin: 5px 0 70px;
    }

    @media (max-width: 576px) and (orientation: portrait) {
        box-shadow: none;
        padding: 30px;
    }
`
const LogoContainer = styled.div`
    margin-bottom: 30px;
    text-align: center;
`
const Logo = styled.img`
    height: auto;
    max-width: 150px;
    width: 100%;
`
const LoginButton = styled(Button)`
    border-radius: 4px;
    margin: 10px 0;
    width: 100%;
`
const LoginUserOutlined = styled(UserOutlined)`
    color: ${props => props.theme.colors.primaryColor};
`
const LoginLockOutlined = styled(LockOutlined)`
    color: ${props => props.theme.colors.primaryColor};
`

// 2 Two-Factor Authentication
const TwoFAContainer = styled.div`
    align-items: center;
    background: ${props => props.theme.colors.primaryBackground};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: auto;
`
const TwoFAForm = styled.div`
    background: ${props => props.theme.colors.secondaryColor};
    border-radius: 4px;
    box-shadow: 0 0 100px rgb(0 0 0 / 8%);
    margin: 150px 0 0;
    padding: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media (max-width: 992px) and (orientation: landscape) {
        margin: 30px 0 70px;
    }
`
const TwoFATitle = styled(Title)`
    font-size: 20px !important;
    margin: 0 0 15px !important;
    text-align: center;
`
const TwoFAText = styled.p`
    margin: 0 0 25px;
    text-align: justify;
`
const OtpButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 100%;

    > button {
        border-radius: 4px;
    }
`
const ErrorMessage = styled.p`
    color: ${props => props.theme.colors.errorColor};
    margin: 15px 0;
    text-align: center;
`

export {
  LoginContainer,
  FormContainer,
  LogoContainer,
  Logo,
  LoginButton,
  LoginUserOutlined,
  LoginLockOutlined,
  TwoFAContainer,
  TwoFAForm,
  TwoFATitle,
  TwoFAText,
  OtpButtonsContainer,
  ErrorMessage
}
