import { useEffect, useCallback, useState } from 'react'
import { message, Button, Row, Col, Modal, Grid, Switch } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks'
import { getUserList, selectUserLists, postUpdateLoginTwoFa, postDeleteUser, selectPostUpdateLoginTwoFaLoading, selectPostUpdateSettlementTwoFaLoading, selectPostDeleteUserLoading, selectGetUserListLoading, postUpdateSettlementTwoFa, selectPostResetPasswordLoading, postResetPassword, selectResetPassword, clearResetPassword } from './reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { isNullOrUndefined } from '../../../utils/utils'
import { UserListsButtonContainer, EnableButton, DisableButton, ErrorMessage, UserListModalButtonsContainer, EnableName, DisableName, ButtonWithIcon } from './styles'
import { ColumnsType } from 'antd/es/table'
import { RouteComponentProps } from 'react-router-dom'
import QRCode from 'react-qr-code'
import OtpInput from 'react-otp-input'
import { LoginFullNameState, SettlementFullNameState, CurrentPagination, LoginRowDetails, SettlementRowDetails, UserIdState } from './interfaces'
import { TableContainer } from '../../../components/styles'
import CreateUserForm from './components/CreateUserForm'
import { RiDeleteBinLine } from 'react-icons/ri'
import { AiOutlineUserAdd } from 'react-icons/ai'
import Table from '../../../components/TableComponent'
import { selectRolePermission } from '../../LoginPage/reducers'
import { ButtonEvent } from '../../../utils/interface'

const { useBreakpoint } = Grid
const UserListPage = (props: RouteComponentProps): JSX.Element => {
  const { history } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const userLists = useAppSelector(selectUserLists)
  const resetPassword = useAppSelector(selectResetPassword)
  const postUpdateLoginTwoFaLoading = useAppSelector(selectPostUpdateLoginTwoFaLoading)
  const postUpdateSettlementTwoFaLoading = useAppSelector(selectPostUpdateSettlementTwoFaLoading)
  const postDeleteUserLoading = useAppSelector(selectPostDeleteUserLoading)
  const postResetPasswordLoading = useAppSelector(selectPostResetPasswordLoading)
  const getUserListLoading = useAppSelector(selectGetUserListLoading)
  const rolePermission = useAppSelector(selectRolePermission)
  const canAddAdmin = rolePermission?.includes('USER_LIST_ADD')
  const canEditAdmin = rolePermission?.includes('USER_LIST_EDIT')
  const can2faAdmin = rolePermission?.includes('USER_LIST_2FA')
  const canRemoveAdmin = rolePermission?.includes('USER_LIST_REMOVE')
  const can2faLogin = rolePermission?.includes('PROFILE_2FA')
  const hasAction = ['USER_LIST_EDIT', 'USER_LIST_REMOVE'].some(r => rolePermission?.includes(r))
  const [loginEnableVisible, setLoginEnableVisible] = useState(false)
  const [loginDisableVisible, setLoginDisableVisible] = useState(false)
  const [settlementEnableVisible, setSettlementEnableVisible] = useState(false)
  const [settlementDisableVisible, setSettlementDisableVisible] = useState(false)
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false)
  const [deleteUserVisible, setDeleteUserVisible] = useState(false)
  const [fullName, setFullName] = useState('')
  const [otp, setOtp] = useState('')
  const [hasError, setHasError] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const [loginRowDetails, setLoginRowDetails] = useState<LoginRowDetails>({
    googleLoginAuthUri: '',
    userId: 0
  })
  const [settlementRowDetails, setSettlementRowDetails] = useState<SettlementRowDetails>({
    googleSettlementAuthUri: '',
    userId: 0
  })
  const [userId, setUserId] = useState<UserIdState>({
    userId: 0
  })
  const [currentPagination, setCurrentPagination] = useState<CurrentPagination>({
    pageIndex: 1,
    pageSize: 10
  })
  const containerStyle = {
    justifyContent: 'center',
    margin: '20px 0'
  }
  const inputStyle = {
    height: '40px',
    width: '40px',
    fontSize: '18px'
  }
  const errorStyle = {
    border: '2px solid #f5222d'
  }
  const resetPasswordModalText = resetPassword.password === ''
    ? (
      <>
        Do you want to reset <EnableName>{fullName}</EnableName> password?
      </>
    )
    : (
      <>
        Generated password for <EnableName>{resetPassword.userName}</EnableName>: <EnableName>{resetPassword.password}</EnableName>
      </>
    )

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ellipsis: true
    },
    {
      title: 'Username',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      ellipsis: true
    },
    {
      title: 'Roles',
      dataIndex: 'merchantUserRolesText',
      ellipsis: true,
      sorter: (a, b) => a.merchantUserRolesText.localeCompare(b.merchantUserRolesText)
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      ellipsis: true
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      ellipsis: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
      sorter: (a, b) => a.email?.localeCompare(b.email)
    },
    {
      title: 'Status',
      dataIndex: 'statusText',
      sorter: (a, b) => a.statusText.localeCompare(b.statusText),
      ellipsis: true
    },
    {
      title: 'Created On',
      dataIndex: 'createdTime',
      ellipsis: true,
      sorter: (a, b) => a.createdTime.localeCompare(b.createdTime),
      render: (value: string) => isNullOrUndefined(value) ? null : dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Login 2FA',
      fixed: screens.xs === true ? false : 'right' as 'right',
      align: 'center',
      ellipsis: true,
      render: (_: any, row: any) => {
        const isEnableLoginTwoFactor: boolean = row.isEnableLoginTwoFactor
        const googleLoginAuthUri: string = row.googleLoginAuthUri
        const userId: number = row.id
        const fullName: string = row.fullName
        const canUpdate2Fa: boolean = row.canUpdate2Fa
        return (
          <>
            <Switch
              disabled={!can2faAdmin || !can2faLogin || !canUpdate2Fa}
              checked={isEnableLoginTwoFactor}
              onClick={() => isEnableLoginTwoFactor ? onHandleLoginDisableTwoFa({ googleLoginAuthUri, userId, fullName }) : onHandleLoginEnableTwoFa({ googleLoginAuthUri, userId })}
            />
          </>
        )
      }
    },
    {
      title: 'Settlement 2FA',
      fixed: screens.xs === true ? false : 'right' as 'right',
      align: 'center',
      ellipsis: true,
      render: (_: any, row: any) => {
        const isEnableSettlementTwoFactor: boolean = row.isEnableSettlementTwoFactor
        const googleSettlementAuthUri: string = row.googleSettlementAuthUri
        const userId: number = row.id
        const fullName: string = row.fullName
        const canUpdate2Fa: boolean = row.canUpdate2Fa
        return (
          <>
            <Switch
              disabled={!can2faAdmin || !canUpdate2Fa}
              checked={isEnableSettlementTwoFactor}
              onClick={() => isEnableSettlementTwoFactor ? onHandleSettlementDisableTwoFa({ googleSettlementAuthUri, userId, fullName }) : onHandleSettlementEnableTwoFa({ googleSettlementAuthUri, userId })}
            />
          </>
        )
      }
    },
    ...hasAction ? [{
      title: 'Action',
      dataIndex: 'Action',
      fixed: screens.xs === true ? false : 'right' as 'right',
      align: 'center' as 'center',
      render: (_: any, row: any) => {
        const fullName: string = row.fullName
        const userId: number = row.id
        const canUpdateOrRemoveUser: boolean = row.canUpdateOrRemoveUser
        return (
          <UserListsButtonContainer>
            {
              canEditAdmin && canUpdateOrRemoveUser &&
                <>
                  <Button type='primary' shape='round' onClick={() => onHandleResetPassword(userId, fullName)}>
                    Reset Password
                  </Button>
                  <Button type='primary' shape='round' onClick={() => onHandleDetail(userId)}>
                    Detail
                  </Button>
                </>
            }
            {
              canRemoveAdmin && canUpdateOrRemoveUser &&
                <DisableButton shape='circle' onClick={() => onHandleDelete(userId, fullName)}>
                  <RiDeleteBinLine />
                </DisableButton>
            }
          </UserListsButtonContainer>
        )
      }
    }] : []
  ]

  const getUserListAction = useCallback(async (): Promise<any> => {
    const payload = {
      pageIndex: 1,
      pageSize: 10
    }

    try {
      await dispatch(getUserList(payload)).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch])

  function onHandleOnChangePagination (page: number, pageSize: number | undefined): any {
    const payload = {
      pageIndex: page,
      pageSize: pageSize
    }
    setCurrentPagination({
      ...payload
    })
    return dispatch(getUserList(payload))
  }

  function onHandleLoginEnableTwoFa ({ googleLoginAuthUri, userId }: LoginRowDetails): void {
    setLoginRowDetails({
      googleLoginAuthUri,
      userId
    })
    setLoginEnableVisible(true)
  }

  function onHandleLoginDisableTwoFa ({ googleLoginAuthUri, userId, fullName }: LoginFullNameState): void {
    setLoginRowDetails({
      googleLoginAuthUri,
      userId
    })
    setFullName(fullName)
    setLoginDisableVisible(true)
  }

  function onHandleSettlementEnableTwoFa ({ googleSettlementAuthUri, userId }: SettlementRowDetails): void {
    setSettlementRowDetails({
      googleSettlementAuthUri,
      userId
    })
    setSettlementEnableVisible(true)
  }

  function onHandleSettlementDisableTwoFa ({ googleSettlementAuthUri, userId, fullName }: SettlementFullNameState): void {
    setSettlementRowDetails({
      googleSettlementAuthUri,
      userId
    })
    setFullName(fullName)
    setSettlementDisableVisible(true)
  }

  function onHandleResetPassword (userId: number, fullName: string): void {
    setUserId({
      userId
    })
    setFullName(fullName)
    setResetPasswordVisible(true)
  }

  function onHandleDelete (userId: number, fullName: string): void {
    setUserId({
      userId
    })
    setFullName(fullName)
    setDeleteUserVisible(true)
  }

  const onHandleLoginEnableTwoConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()

    const payload = {
      active: true,
      userId: loginRowDetails.userId,
      otp
    }

    try {
      if (otp.length < 6) {
        return setHasError(true)
      } else {
        await dispatch(postUpdateLoginTwoFa(payload)).then(unwrapResult)
        await dispatch(getUserList(currentPagination))
        await setLoginEnableVisible(false)
        await setOtp('')
        return await message.success(`Two-factor Login Authentication for user ${fullName} was enabled successfully.`)
      }
    } catch (error: any) {
      await setOtp('')
      return await message.error(error.message)
    }
  }

  const onHandleLoginDisableTwoConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()
    const payload = {
      active: false,
      userId: loginRowDetails.userId
    }

    try {
      await dispatch(postUpdateLoginTwoFa(payload)).then(unwrapResult)
      await dispatch(getUserList(currentPagination))
      await setLoginDisableVisible(false)
      await setOtp('')
      return await message.success(`Two-factor Login Authentication for user ${fullName} was disabled successfully.`)
    } catch (error: any) {
      await setOtp('')
      return await message.error(error.message)
    }
  }

  const onHandleSettlementEnableTwoConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()

    const payload = {
      active: true,
      userId: settlementRowDetails.userId,
      otp
    }

    try {
      if (otp.length < 6) {
        return setHasError(true)
      } else {
        await dispatch(postUpdateSettlementTwoFa(payload)).then(unwrapResult)
        await dispatch(getUserList(currentPagination))
        await setSettlementEnableVisible(false)
        await setOtp('')
        return await message.success(`Two-factor Settlement Authentication for user ${fullName} was enabled successfully.`)
      }
    } catch (error: any) {
      await setOtp('')
      return await message.error(error.message)
    }
  }

  const onHandleSettlementDisableTwoConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()
    const payload = {
      active: false,
      userId: settlementRowDetails.userId
    }

    try {
      await dispatch(postUpdateSettlementTwoFa(payload)).then(unwrapResult)
      await dispatch(getUserList(currentPagination))
      await setSettlementDisableVisible(false)
      await setOtp('')
      return await message.success(`Two-factor Settlement Authentication for user ${fullName} was disabled successfully.`)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  const onHandleDeleteConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()

    const payload = {
      id: userId.userId,
      deleted: true
    }
    try {
      await dispatch(postDeleteUser(payload)).then(unwrapResult)
      await dispatch(getUserList(currentPagination))
      await setDeleteUserVisible(false)
      return await message.success(`Delete ${fullName} User Success!`)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  const onHandleResetPasswordConfirm = async (e: ButtonEvent): Promise<any> => {
    e.preventDefault()

    const payload = {
      agentId: userId.userId
    }
    try {
      await dispatch(postResetPassword(payload)).then(unwrapResult)
      return await message.success(`Reset Password ${fullName} User Success!`)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleDoneResetPassword (): void {
    dispatch(clearResetPassword())
    setResetPasswordVisible(false)
  }

  function onHandleCancelModals (): void {
    setOtp('')
    setLoginEnableVisible(false)
    setLoginDisableVisible(false)
    setSettlementEnableVisible(false)
    setSettlementDisableVisible(false)
    setResetPasswordVisible(false)
    setDeleteUserVisible(false)
  }

  function onHandleDetail (userId: number): void {
    history.push(`/administrator/user-list/${userId}`)
  }

  function onHandleAddUser (): void {
    setAddVisible(true)
  }

  function onHandleChangeOtp (otp: string): void {
    setHasError(false)
    setOtp(otp)
  }

  useEffect(() => {
    getUserListAction().finally(() => {})
  }, [getUserListAction])

  return (
    <>
      {
        canAddAdmin &&
          <Row justify='end'>
            <Col style={{ textAlign: 'right' }} xs={24} sm={{ span: 5, offset: 13 }} md={{ span: 4, offset: 15 }} xl={{ span: 3, offset: 0 }} xxl={{ span: 2, offset: 9 }}>
              <ButtonWithIcon
                type='primary'
                shape='round'
                onClick={onHandleAddUser}
                block={screens.xs}
              >
                <AiOutlineUserAdd />  Add
              </ButtonWithIcon>
            </Col>
          </Row>
      }
      <TableContainer>
        <Table
          dataSource={userLists.data}
          loading={getUserListLoading}
          columns={columns}
          current={currentPagination.pageIndex}
          pageSize={currentPagination.pageSize}
          total={userLists.total}
          onChange={onHandleOnChangePagination}
        />
      </TableContainer>
      {/* Login 2FA Modals */}
      <Modal
        open={loginEnableVisible}
        title='Enable Login Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        bodyStyle={{
          textAlign: 'center'
        }}
        footer={null}
      >
        <QRCode value={loginRowDetails.googleLoginAuthUri} />
        <OtpInput
          value={otp}
          onChange={onHandleChangeOtp}
          numInputs={6}
          separator={<span>-</span>}
          shouldAutoFocus
          isInputNum
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          hasErrored={hasError}
          errorStyle={errorStyle}
        />
        {
          hasError &&
            <ErrorMessage>OTP is required.</ErrorMessage>
        }
        <UserListModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <EnableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleLoginEnableTwoConfirm}
            loading={postUpdateLoginTwoFaLoading}
          >
            Submit
          </EnableButton>
        </UserListModalButtonsContainer>
      </Modal>
      <Modal
        open={loginDisableVisible}
        title='Disable Login Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        Disable Login Two-factor Authentication for <DisableName>{fullName}</DisableName>?
        <UserListModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <DisableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleLoginDisableTwoConfirm}
            loading={postUpdateLoginTwoFaLoading}
          >
            Confirm
          </DisableButton>
        </UserListModalButtonsContainer>
      </Modal>
      {/* Settlement 2FA Modals */}
      <Modal
        open={settlementEnableVisible}
        title='Enable Settlement Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        bodyStyle={{
          textAlign: 'center'
        }}
        footer={null}
      >
        <QRCode value={settlementRowDetails.googleSettlementAuthUri} />
        <OtpInput
          value={otp}
          onChange={onHandleChangeOtp}
          numInputs={6}
          separator={<span>-</span>}
          shouldAutoFocus
          isInputNum
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          hasErrored={hasError}
          errorStyle={errorStyle}
        />
        {
          hasError &&
            <ErrorMessage>OTP is required.</ErrorMessage>
        }
        <UserListModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <EnableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleSettlementEnableTwoConfirm}
            loading={postUpdateSettlementTwoFaLoading}
          >
            Submit
          </EnableButton>
        </UserListModalButtonsContainer>
      </Modal>
      <Modal
        open={settlementDisableVisible}
        title='Disable Settlement Two-Factor Authentication'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        Disable Settlement Two-factor Authentication for <DisableName>{fullName}</DisableName>?
        <UserListModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <DisableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleSettlementDisableTwoConfirm}
            loading={postUpdateSettlementTwoFaLoading}
          >
            Confirm
          </DisableButton>
        </UserListModalButtonsContainer>
      </Modal>
      {/* Reset Password Modal */}
      <Modal
        open={resetPasswordVisible}
        title='Reset Password'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        {resetPasswordModalText}
        <UserListModalButtonsContainer>
          {
            resetPassword.password === '' &&
              <>
                <Button
                  key='back'
                  shape='round'
                  block={screens.xs}
                  onClick={onHandleCancelModals}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  key='submit'
                  shape='round'
                  block={screens.xs}
                  onClick={onHandleResetPasswordConfirm}
                  loading={postResetPasswordLoading}
                >
                  Confirm
                </Button>
              </>
          }
          {
            resetPassword.password !== '' &&
              <Button
                type='primary' key='submit'
                shape='round'
                block={screens.xs}
                onClick={onHandleDoneResetPassword}
              >
                Done
              </Button>
          }
        </UserListModalButtonsContainer>
      </Modal>
      {/* Delete User Modal */}
      <Modal
        open={deleteUserVisible}
        title='Delete User'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        Are you sure you would like to remove this <DisableName>{fullName}</DisableName>?
        <UserListModalButtonsContainer>
          <Button
            key='back'
            shape='round'
            block={screens.xs}
            onClick={onHandleCancelModals}
          >
            Cancel
          </Button>
          <DisableButton
            key='submit'
            shape='round'
            block={screens.xs}
            onClick={onHandleDeleteConfirm}
            loading={postDeleteUserLoading}
          >
            Confirm
          </DisableButton>
        </UserListModalButtonsContainer>
      </Modal>
      {/* Add User Modal */}
      <Modal
        open={addVisible}
        title='Create User'
        keyboard={false}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={null}
      >
        <CreateUserForm setAddVisible={setAddVisible} />
      </Modal>
    </>
  )
}

export default UserListPage
