import { createSlice, createAsyncThunk, createAction, isAnyOf, ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import { AuthState, LoginSubmitValues, RefreshTokenState, RejectWithValueState, GetState, SelectListState, DecodedTokenState, PostVerifyTwoFaState } from './interfaces'
import api from '../../utils/api'
import jwt_decode from 'jwt-decode'
import { isEmptyObj } from '../../utils/utils'

// Async Requests
export const postLogin = createAsyncThunk<AuthState, LoginSubmitValues, { rejectValue: RejectWithValueState, dispatch: ThunkDispatch<GetState, unknown, AnyAction> }>(
  'auth/postLogin',
  async ({ userName, password }, { rejectWithValue, dispatch }) => {
    const response = await api.post('/auth/login', {
      userName,
      password
    })
    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }
    const { authToken } = response.data
    const decodedAuthToken: DecodedTokenState = jwt_decode(authToken)

    if (decodedAuthToken.IsLogin2FAEnabled.toLowerCase() !== 'true') {
      await dispatch(getSelectList(authToken))
    }
    return response.data
  }
)

export const postRefreshToken = createAsyncThunk<AuthState, RefreshTokenState, { rejectValue: RejectWithValueState, dispatch: ThunkDispatch<GetState, unknown, AnyAction> }>(
  'auth/postRefreshToken',
  async ({ authenticationToken, refreshToken }, { rejectWithValue, dispatch }) => {
    const response = await api.post('/auth/refresh-token', {
      authenticationToken,
      refreshToken
    }, {
      headers: {
        Authorization: `Bearer ${authenticationToken}`
      }
    })
    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }

    const { authToken } = response.data
    await dispatch(getSelectList(authToken))
    return response.data
  }
)

export const postVerifyTwoFa = createAsyncThunk<PostVerifyTwoFaState, string, { state: GetState, rejectValue: RejectWithValueState, dispatch: ThunkDispatch<GetState, unknown, AnyAction> }>(
  'auth/postVerifyTwoFa',
  async (otp, { getState, rejectWithValue, dispatch }) => {
    const { authReducer: { authToken } } = getState()
    const response = await api.post('/auth/verify-2fa-login-code', {
      otp: otp
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
    const hasError: boolean = response.data.hasError

    if (hasError) {
      return rejectWithValue({ message: response.data.message })
    }
    const { value: { authToken: auth } } = response.data
    await dispatch(getSelectList(auth))
    return response.data
  }
)

export const getSelectList = createAsyncThunk<SelectListState, string | undefined, { state: GetState }>(
  'auth/selectList',
  async (authToken, { getState }) => {
    const auth = authToken != null ? authToken : getState().authReducer.authToken
    const response = await api.get('/look-up/select-list', {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
    return response.data
  }
)

// Initial States
const authInitialState: AuthState = {
  authToken: '',
  refreshToken: '',
  authLoading: false,
  selectList: {},
  isEnableLoginTwoFactor: false,
  isTwoFaLoginVerified: false,
  merchantId: 0,
  rolePermission: []
}

// Slices
const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.fulfilled, (state, action) => {
        const { authToken, refreshToken, googleLoginAuthUri } = action.payload
        const decodedAuthToken: DecodedTokenState = jwt_decode(authToken)
        const rolePermission: string = decodedAuthToken.RolePermission
        const isEnableLoginTwoFactor = decodedAuthToken.IsLogin2FAEnabled.toLowerCase() === 'true'

        state.authToken = authToken
        state.refreshToken = refreshToken
        state.isEnableLoginTwoFactor = isEnableLoginTwoFactor
        state.merchantId = parseInt(decodedAuthToken.MerchantID)
        if (!isEnableLoginTwoFactor) {
          state.rolePermission = rolePermission.split(',')
        }

        if (googleLoginAuthUri) {
          state.googleLoginAuthUri = googleLoginAuthUri
        }
      })
      .addCase(postVerifyTwoFa.fulfilled, (state, action) => {
        const { value: { authToken, refreshToken } } = action.payload
        const decodedAuth2faLoginToken: DecodedTokenState = jwt_decode(authToken)

        state.googleLoginAuthUri = undefined
        state.authToken = authToken
        state.refreshToken = refreshToken
        state.rolePermission = decodedAuth2faLoginToken.RolePermission.split(',')
        state.isTwoFaLoginVerified = decodedAuth2faLoginToken.IsLogin2FAVerified.toLowerCase() === 'true'
      })
      .addCase(postRefreshToken.fulfilled, (state, action) => {
        const { authToken, refreshToken } = action.payload

        state.authToken = authToken
        state.refreshToken = refreshToken
      })
      .addCase(getSelectList.fulfilled, (state, action) => {
        state.selectList = action.payload
      })
      .addMatcher(isAnyOf(postLogin.fulfilled, postVerifyTwoFa.fulfilled, postVerifyTwoFa.rejected, postLogin.rejected, getSelectList.fulfilled, getSelectList.rejected), (state) => {
        state.authLoading = false
      })
      .addMatcher(isAnyOf(postLogin.pending, postVerifyTwoFa.pending, getSelectList.pending), (state) => {
        state.authLoading = true
      })
  }
})

// selectors
export const selectAuthLoading = (state: RootState): boolean => state.authReducer.authLoading
export const selectAuthToken = (state: RootState): string => state.authReducer.authToken
export const selectRefreshToken = (state: RootState): string => state.authReducer.refreshToken
export const selectIsLoggedIn = (state: RootState): boolean => state.authReducer.authToken !== '' && !isEmptyObj(state.authReducer.selectList) // this to make sure if there's an authToken and selectlist
export const selectSelectList = (state: RootState): SelectListState => state.authReducer.selectList
export const selectIsEnableLoginTwoFactor = (state: RootState): boolean => state.authReducer.isEnableLoginTwoFactor
export const selectIsTwoFaLoginVerified = (state: RootState): boolean => state.authReducer.isTwoFaLoginVerified
export const selectMerchantId = (state: RootState): number => state.authReducer.merchantId
export const selectRolePermission = (state: RootState): string[] => state.authReducer.rolePermission
export const selectGoogleLoginAuthUri = (state: RootState): string | undefined => state.authReducer.googleLoginAuthUri

// actions
export const logOut = createAction('LOGOUT')

export default authSlice.reducer
